{
  "name": "fuse-angular",
  "version": "1.71.0",
  "description": "Fuse - Angular Admin Template and Starter Project",
  "author": "https://themeforest.net/user/srcn",
  "license": "https://themeforest.net/licenses/standard",
  "private": true,
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "start:dev": "ng serve -c development",
    "start:prod": "ng serve -c production",
    "build": "ng build",
    "build:dev": "ng build -c development",
    "build:prod": "ng build -c production",
    "watch": "ng build --watch -c development",
    "test": "ng test"
  },
  "dependencies": {
    "@angular/animations": "17.3.8",
    "@angular/cdk": "17.3.9",
    "@angular/common": "17.3.8",
    "@angular/compiler": "17.3.8",
    "@angular/core": "17.3.8",
    "@angular/forms": "17.3.8",
    "@angular/material": "17.3.9",
    "@angular/material-luxon-adapter": "17.3.9",
    "@angular/platform-browser": "17.3.8",
    "@angular/platform-browser-dynamic": "17.3.8",
    "@angular/router": "17.3.8",
    "@auth0/angular-jwt": "^5.2.0",
    "@jsverse/transloco": "^7.4.1",
    "@jsverse/transloco-persist-lang": "^7.0.0",
    "@jsverse/transloco-persist-translations": "^7.0.0",
    "@ngrx/effects": "^17.2.0",
    "@ngrx/entity": "^17.2.0",
    "@ngrx/router-store": "^17.2.0",
    "@ngrx/store": "^17.2.0",
    "@ngrx/store-devtools": "^17.2.0",
    "@ngxpert/hot-toast": "^2.0.0",
    "angular-user-idle": "^4.0.0",
    "apexcharts": "^3.49.1",
    "crypto-js": "4.2.0",
    "highlight.js": "11.9.0",
    "lodash-es": "4.17.21",
    "luxon": "3.4.4",
    "ng-apexcharts": "^1.10.0",
    "ng-recaptcha": "^13.2.1",
    "ngx-image-cropper": "^8.0.0",
    "ngx-quill": "^25.3.2",
    "perfect-scrollbar": "1.5.5",
    "rxjs": "7.8.1",
    "tslib": "2.6.2",
    "zone.js": "^0.14.5"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "17.3.7",
    "@angular/cli": "17.3.7",
    "@angular/compiler-cli": "17.3.8",
    "@semantic-release/git": "^10.0.1",
    "@semantic-release/gitlab": "^13.1.0",
    "@semantic-release/npm": "^12.0.1",
    "@tailwindcss/typography": "0.5.13",
    "@types/chroma-js": "2.4.4",
    "@types/crypto-js": "4.2.2",
    "@types/highlight.js": "10.1.0",
    "@types/jasmine": "5.1.4",
    "@types/lodash": "^4.17.1",
    "@types/lodash-es": "4.17.12",
    "@types/luxon": "3.4.2",
    "@types/node": "^20.12.12",
    "autoprefixer": "^10.4.19",
    "chroma-js": "2.4.2",
    "jasmine-core": "5.1.2",
    "karma": "6.4.3",
    "karma-chrome-launcher": "3.2.0",
    "karma-coverage": "2.2.1",
    "karma-jasmine": "5.1.0",
    "karma-jasmine-html-reporter": "2.1.0",
    "lodash": "4.17.21",
    "postcss": "^8.4.38",
    "prettier": "^3.2.5",
    "prettier-plugin-tailwindcss": "^0.5.14",
    "tailwindcss": "^3.4.3",
    "typescript": "^5.4.5"
  }
}
