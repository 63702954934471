import { IEnvironment } from './environment.type';

export const environment: IEnvironment = {
  production: false,
  version: `${require('../../package.json').version}-dev`,
  apiPublicUrl: 'https://fcloud-test.festina.com/public/dmz/services',
  apiPrivateUrl: 'https://wspriv-test.festina.com/private/services',
  JWTAllowedDomains: ['fcloud-test.festina.com', 'wspriv-test.festina.com'],
  mock: {
    enabled: false
  },
  reCaptcha: {
    enabled: false,
    siteKey: '6LdKOoAaAAAAAI3SwhUbNbdxq5dxAIPQKq8DLvlf'
  },
  passwordValidator: '^(?=.*[a-zA-Z])(?=.*\\d.*)[a-zA-Z0-9\\S]{6,45}$'
};
